









import { Vue, Prop, Component } from "vue-property-decorator";
import { XIcon } from "vue-feather-icons";
@Component({
  components: {
    XIcon,
  },
})
export default class Chip extends Vue {
  @Prop(String) private text!: string;
  @Prop({ type: Boolean, default: false }) private deletable!: boolean;
  @Prop({ type: Boolean, default: false }) private clickable!: boolean;

  private onClick() {
    if (this.clickable) {
      this.$router.push(`/tag/${this.text}`);
    }
  }

  private get classes() {
    if (this.clickable) {
      return "chip--clickable";
    }
    return "chip--unclickable";
  }
}
