






















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Navigation extends Vue {
  @Prop() private getSwiper!: () => any;
  @Prop() private maxComic!: number;
  @Prop() private page!: number;
  @Prop() private next!: number;
  @Prop() private previous!: number;
  @Prop() private navigateFirst!: () => void;
  @Prop() private navigatePrevious!: () => void;
  @Prop() private navigateNext!: () => void;
  @Prop() private navigateLast!: () => void;

  public get nextURL(): string {
    return this.getLink(this.next);
  }

  public get previousURL(): string {
    return this.getLink(this.previous);
  }

  public get firstURL(): string {
    return this.getLink(1);
  }

  public get lastURL(): string {
    return this.getLink(this.maxComic);
  }

  private get home(): string {
    return "/";
  }

  private getLink(index?: number): string {
    return "/comic/" + index;
  }

  private get nextExists(): boolean {
    return !!this.next;
  }

  private get previousExists(): boolean {
    return !!this.previous;
  }
}
